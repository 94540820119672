<template>
  <WrapperSkeleton>
    <template v-slot:skeleton>
      <SkeletonElement :skeletonType="skeleton" />
    </template>
  </WrapperSkeleton>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  props: {
    skeleton: {
      type: String,
      default: 'input',
    },
  },
  components: {
    WrapperSkeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "WrapperSkeleton" */ '@/modules/cms/skeletons/WrapperSkeleton.vue'
      )
    ),
    SkeletonElement: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "SkeletonElement" */ '@/modules/cms/skeletons/SkeletonElement.vue'
      )
    ),
  },
};
</script>
